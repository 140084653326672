import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../../axiosAPI";
import { useDispatch } from "react-redux";
import { setErrors } from "../../actions/authActions";
import moment from "moment-timezone";

export default function ReceiptDetail() {
  const { billId } = useParams();
  const dispatch = useDispatch();
  const [receipt, setReceipt] = useState(null);

  const fetchReceiptDetails = () => {
    axiosInstance.defaults.headers["Authorization"] =
      "Bearer " + localStorage.access_token;

    let hotel_id = localStorage.getItem("hotel_id");

    axiosInstance
      .get(`/order_ticket?hotel=${hotel_id}&bill_id=${billId}`)
      .then((res) => {
        const receiptData = {
          bill: res.data[0].bill,
          hotel: {
            hotel_name: res.data[0].hotel_name,
          },
          type: res.data[0].type,
          status: res.data[0].status,
        };

        receiptData.orderDetails = res.data.map((order) => ({
          product: order.product,
          variant: order.variant,
          addon: order.addon,
          quantity: order.quantity,
          status: order.status,
        }));

        setReceipt(receiptData);
        console.log(receiptData);
      })
      .catch((err) => {
        console.log(err);
        dispatch(setErrors({ detail: err.response.data[0] }));
      });
  };

  const calculateOptionsPrice = (order) => {
    let totalPrice = order.product.net_price;

    if (order.variant) {
      totalPrice = totalPrice + order.variant.price;
    }

    if (order.addon.length > 0) {
      for (let i = 0; i < order.addon.length; i++) {
        totalPrice = totalPrice + order.addon[i].price;
      }
    }

    return totalPrice * order.quantity;
  };

  useEffect(() => {
    fetchReceiptDetails();
  }, []);

  return (
    receipt && (
      <div className="p-2 border-2 mx-auto pb-3 w-[350px]">
        <div>
          <div className="flex flex-col items-center border-b-2 pb-1 mb-2 ">
            <h4 className="text-center font-semibold text-lg">
              {receipt.hotel.hotel_name}
            </h4>
          </div>
        </div>
        <div className="text-sm">
          <div className="flex flex-col text-left">
            Date: {moment(receipt.bill.created_at).format("DD/MM/YY, hh:mm A")}
          </div>
          <div className="flex justify-between text-left">
            <p>Bill No: {billId}</p>
            {receipt.bill.customer && receipt.bill.customer.customer_name && (
              <p>Customer: {receipt.bill.customer.customer_name}</p>
            )}
          </div>
          <div className="flex justify-between text-left">
            {/* <p>Order No: {billId}</p> */}
            {receipt.bill.customer && receipt.bill.customer.customer_id && (
              <p>CID: {receipt.bill.customer.customer_id}</p>
            )}
          </div>
        </div>
        <table className="w-full my-2 text-sm" border="1">
          <thead>
            <tr>
              <th className="px-1 py-2 border-b-2 border-gray-200 bg-gray-50 text-center text-xs font-semibold text-gray-700 uppercase tracking-wider">
                Item
              </th>
              <th className="px-1 py-2 border-b-2 border-gray-200 bg-gray-50 text-center text-xs font-semibold text-gray-700 uppercase tracking-wider">
                Quantity
              </th>
              <th className="px-1 py-2 border-b-2 border-gray-200 bg-gray-50 text-center text-xs font-semibold text-gray-700 uppercase tracking-wider">
                Price
              </th>
            </tr>
          </thead>
          <tbody>
            {receipt.orderDetails.map((order, i) => {
              return order.status === "rejected" ? (
                <></>
              ) : (
                <tr>
                  <td
                    className={`px-1 py-1 border-b border-gray-200 ${
                      order.status === "rejected" ? "bg-red-200" : "bg-white"
                    } text-sm`}
                  >
                    <p className="text-[#4338ca] whitespace-no-wrap ml-2">
                      {order.product.name}
                    </p>
                    {order.variant && (
                      <p className=" whitespace-no-wrap ml-2 text-[12px]">
                        {order.variant.variant_value} - &#8377;
                        {order.variant.price}
                      </p>
                    )}
                    {order.addon &&
                      order.addon.map((order, i) => (
                        <p className=" whitespace-no-wrap ml-2 text-[12px]">
                          {order.add_on_value} - &#8377;{order.price}
                        </p>
                      ))}
                  </td>
                  <td
                    className={`px-1 py-1 border-b border-gray-200 ${
                      order.status === "rejected" ? "bg-red-200" : "bg-white"
                    } test-sm`}
                  >
                    <p className="whitespace-no-wrap w-fit p-1">
                      {order.quantity}
                    </p>
                  </td>
                  <td
                    className={`px-1 py-1 border-b border-gray-200 ${
                      order.status === "rejected" ? "bg-red-200" : "bg-white"
                    } test-sm`}
                  >
                    <p id={order.id} className="whitespace-no-wrap w-fit p-1">
                      {calculateOptionsPrice(order)}
                    </p>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <p />

        <div className="text-sm my-2 w-full flex flex-col items-end">
          <div className="my-1">
            Bill Amount : &#8377;{receipt.bill.total_amount}
          </div>
          <div className="my-1">
            Discount : {receipt.bill.discount_value}
            {receipt.bill.discount_type === "percent" ? "%" : "/-"}
          </div>
          <div className="my-1">
            Bill Payable : &#8377;{receipt.bill.net_amount}
          </div>
        </div>
        <div className="my-1 text-center">
          Bill Status :{" "}
          {receipt.bill.bill_status === "paid" ? "Paid" : "Unpaid"}
        </div>
        <p />
        <div>
          <div className="flex flex-col text-center">
            &#127801;Thank You...Visit Again!!&#127801;
          </div>
        </div>
      </div>
    )
  );
}
