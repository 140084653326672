import { Route, Routes } from "react-router-dom";
import MainPage from "./Layout/MainPage";
import { Provider, useSelector } from "react-redux";
import store from "./store";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser, clearErrors } from "./actions/authActions";
import AuthPage from "./Components/Auth/AuthPage";
import PrivateRoute from "./Components/private-route/PrivateRoute";
import { useEffect } from "react";
import { toast } from "react-toastify";

console.log(localStorage);
if (localStorage.access_token) {
  const token = localStorage.access_token;
  // setAuthToken(token);
  const decoded = jwt_decode(token);
  console.log(decoded);
  store.dispatch(setCurrentUser(decoded));

  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());
    window.location.href = "./login";
  }

  const hotel_id = localStorage.hotel_id;
  const pathname = window.location.pathname;
  console.log(pathname);
  if (pathname !== "/register" && hotel_id == -1) {
    window.location.href = "./register";
  }
}

function App() {
  const errorMessage = useSelector((state) => state.errors);

  useEffect(() => {
    toast.error(errorMessage.errors.detail, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    store.dispatch(clearErrors());
  }, [errorMessage.errors.detail]);

  return (
    <Routes>
      <Route exact path="/auth" element={<AuthPage />} />
      <Route element={<PrivateRoute />}>
        <Route path="*" element={<MainPage />} />
      </Route>
      {/* <Route exact path="/auth" element={<AuthPage />} />  
        <Route path="*" element={<MainPage />} /> */}
    </Routes>
  );
}

function AppWrapper() {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
}

export default AppWrapper;
